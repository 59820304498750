
import XLSX from 'xlsx/dist/xlsx.mini.min'
import { Component, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import Contact from '../components/Contact.vue'

const _SheetJSFT = ['xlsx', 'csv'].map(x => '.' + x).join(',')

@Component({ components: { Contact } })
export default class FileUpload extends Vue {
  data = []
  bulkShops = []
  fileUploaded = false
  loading = false
  saved = false
  saveFailed = false
  cols = [
    { text: 'Company', value: 'Company', width: '200px' },
    { text: 'Domain', value: 'domain', width: '200px' },
    { text: 'Sales Revenue USD', value: 'salesRevenue', width: '100px' },
    { text: 'Page Rank', value: 'pageRank', width: '100px' },
    { text: 'Contacts', value: 'contacts', width: '250px' }
    // { text: 'Emails', value: 'Emails', width: '250px' },
    // { text: 'Name', value: 'Name', width: '150px' },
    // { text: 'Title', value: 'Title', width: '150px' }
  ]

  SheetJSFT = _SheetJSFT

  onChange(file) {
    this.loading = true
    const reader = new FileReader()
    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' })
      const data: IImportRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

      const socialNetworks = ['Twitter', 'Facebook', 'Pinterest', 'Instagram', 'TikTok']

      const grouped = data.reduce((acc, row) => {
        if (!acc[row.Domain]) acc[row.Domain] = []
        acc[row.Domain].push(row)
        return acc
      }, {} as { [key: string]: IImportRow[] })

      this.data = Object.keys(grouped).map(domain => {
        const rows = grouped[domain]
        const firstRow = rows[0]

        const contacts = rows.map(r => ({ name: r.Name, title: r.Title, email: r.Email }))
        const links = {}

        for (const key in firstRow) {
          if (socialNetworks.includes(key) && firstRow[key]) {
            links[key.toLowerCase()] = firstRow[key]
          }
        }

        let pageRank = +firstRow['Page Rank']

        return {
          domain,
          contacts,
          links,
          pageRank: isNaN(pageRank) ? undefined : pageRank,
          salesRevenue: (firstRow['Sales Revenue USD'] || '').replace('$', '')
        }
      })

      // const groupedByDomain = _.chain(data)
      //   .groupBy('Domain')
      //   .map((value, key) => ({ Domain: key, data: value }))
      //   .value()
      // this.setData(groupedByDomain)
      this.loading = false
    }
    reader.readAsBinaryString(file)
    this.fileUploaded = true
  }

  // setData(groupedData) {
  //   groupedData.forEach(row => {
  //     let formatedRow: any = {}
  //     row.data.forEach(record => {
  //       for (const key in record) {
  //         if (key === 'Domain' || key === 'Company' || key === 'Telephones' || key === 'Emails' || key === 'Page Rank')
  //           formatedRow[key] = record[key] && record[key]
  //         else formatedRow[key] = (formatedRow[key] ? formatedRow[key] + '; ' : '') + (record[key] && record[key])
  //       }
  //     })
  //     this.data.push(formatedRow)
  //   })

  //   this.data.forEach((row: IImportRow) => {
  //     const names: string[] = row.Name && row.Name.split(';')
  //     const titles: string[] = row.Title && row.Title.split(';')
  //     const emails: string[] = row.Email && row.Email.split(';')

  //     const contacts: yakkyo.IShopMonitorContact[] = names.map((_n, index) => ({
  //       name: names[index],
  //       title: titles[index],
  //       email: emails[index]
  //     }))

  //     this.bulkShops.push({ domain: row.Domain, contacts })
  //   })
  // }

  async saveData() {
    try {
      let saveReq = await this.$store.state.apiClient.saveShops(this.data)
      if (saveReq.status === 200) this.saved = true
      // this.$router.push({ name: 'Dashboard' })
    } catch (err) {
      this.saveFailed = true
      console.log(err)
    }
  }
}

interface IImportRow {
  Name: string // "Evan Marks
  Title: string // "Other
  Email: string // "Unknown
  Domain: string //  "100percentpure.com
  'Location on Site': string //  "100percentpure.com
  'Tech Spend USD': string //  "$5000
  'Sales Revenue USD': string //  "$492629
  Company: string //  "100% PURE
  Vertical: string //  "Style And Fashion
  Tranco: string //  "19451
  'Page Rank': number //  "322526
  Majestic: string //  "61113
  Umbrella: string //  "Outside Top 1m
  Telephones: string //  "ph: +1-415-814-9788;+1-844-787-3100;+1-408-758-6559;+1-510-548-8595
  Twitter: string //  "https://twitter.com/100percentpure
  Facebook: string //  "https://facebook.com/100percentpure
  Pinterest: string //  "https://pinterest.com/100percentpure
  Instagram: string //  "https://instagram.com/100percentpure
  TikTok: string //  "https://tiktok.com/@100percentpure
  People: string //  "N/A - Other - noemail
  City: string //  "San Jose
  State: string //  "CA
  Zip: string //  "95131
  Country: string //  "US
  'First Detected': string //  "2017-07-10
  'Last Found': string //  "2021-08-04
  'First Indexed': string //  "2011-01-03
  'Last Indexed': string //  "2021-08-04
  Exclusion: string //  "-
  GDPR: string //  "California CCPA"
}
